
import {
    computed, defineComponent, reactive, watch,
} from 'vue';
import OrbisCard from '@/components/OrbisCard.vue';
import QuantityPicker from '@/components/QuantityPicker.vue';
import Screen from '@/components/layout/Screen.vue';
import Item from '@/domain/Item';
import InventoryFlatDTO from '@/dtos/InventoryFlatDTO';
import InventoryService from '@/services/InventoryService';
import LabelGenerationService from '@/services/LabelGenerationService';
import CoreStore from '@/store/CoreStore';
import { ItemType } from '@/domain/enums/ItemType';
import ItemPicker from '@/components/ItemPicker.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';

type State = {
    componentKey: number;
    combinedContainer?: Item;
    combinedContainerFinishedInventoryQty: {
        [key: number]: number;
    };
    combinedContainerQtyBuilt?: number;
    submittingInventory: boolean;
};

export default defineComponent({
    components: {
        Thumbnail,
        OrbisCard,
        QuantityPicker,
        Screen,
        ItemPicker,
        SmartTrakFooter,
        BRow,
        BCol,
        BFormInput,
    },
    props: {
        deconstruct: Boolean,
    },
    setup(props) {
        const inventoryService = new InventoryService();
        const labelGenerationService = new LabelGenerationService();
        const state = reactive<State>({
            componentKey: 0,
            combinedContainerFinishedInventoryQty: {},
            submittingInventory: false,
        });
        const { canPrintUnitLoadLabels } = CoreStore.getInstance().metaDataStore;

        function resetForm() {
            state.componentKey += 1;
            state.combinedContainer = undefined;
            state.combinedContainerFinishedInventoryQty = {};
            state.submittingInventory = false;
            state.combinedContainerQtyBuilt = undefined;
        }

        const { userLocation } = CoreStore.getInstance().profileStore;
        const saveable = computed(() => !state.submittingInventory && state.combinedContainerQtyBuilt && state.combinedContainer?.isUnitLoad);

        const combinedContainerDisplayTitle = computed((): string => {
            if (state.combinedContainer) {
                return `${getTitleCaseTranslation('core.domain.onHandInventory')}: ${state.combinedContainerFinishedInventoryQty[state.combinedContainer.id]}`;
            }

            return '';
        });

        const placeholder = computed(
            (): string => `${getTranslation('core.common.typeToSelectUnitLoadsTo')} ${getTitleCaseTranslation(props.deconstruct ? 'core.common.disassemble' : 'core.common.assemble')}...`,
        );
        const pageTitle = computed((): string => getTitleCaseTranslation(props.deconstruct ? 'core.common.disassembleUnitLoads' : 'core.common.assembleUnitLoads'));
        const quantityPlaceholderLabel = computed(
            (): string => `${getTitleCaseTranslation('core.domain.quantity')} ${getTitleCaseTranslation(props.deconstruct ? 'core.common.disassembled' : 'core.common.assembled')}`,
        );

        async function onCombinedContainerSelect(data: { item: Item }) {
            if (data.item) {
                state.combinedContainer = data.item;

                const finishedGoodInventory = await inventoryService.getFinishedInventoryForCombinedContainer(state.combinedContainer.id, userLocation.id);

                state.combinedContainerFinishedInventoryQty = {};
                finishedGoodInventory.forEach((inv: InventoryFlatDTO) => {
                    state.combinedContainerFinishedInventoryQty[inv.itemId] = inv.quantity;
                });
            }
        }

        async function onSubmit() {
            state.submittingInventory = true;

            if (state.combinedContainer && state.combinedContainerQtyBuilt) {
                let success = false;

                if (props.deconstruct) {
                    success = await inventoryService.deconstructCombinedContainers(state.combinedContainer, userLocation.id, state.combinedContainerQtyBuilt);
                } else {
                    success = await inventoryService.createCombinedContainers(state.combinedContainer, userLocation.id, state.combinedContainerQtyBuilt);

                    if (success && canPrintUnitLoadLabels) {
                        const labelUrl = await labelGenerationService.getAssembleUnitLoadLabelUrl(state.combinedContainer.id, state.combinedContainerQtyBuilt);

                        if (labelUrl) {
                            window.location.href = labelUrl;
                        }
                    }
                }

                if (success) {
                    resetForm();
                }
            }

            state.submittingInventory = false;
        }

        watch(
            () => props.deconstruct,
            () => {
                // deconstruct changes the page mode - reset
                resetForm();
            },
            { immediate: true },
        );

        return {
            combinedContainerDisplayTitle,
            onCombinedContainerSelect,
            onSubmit,
            resetForm,
            pageTitle,
            quantityPlaceholderLabel,
            state,
            ItemType,
            placeholder,
            saveable,
            getTranslation,
            getTitleCaseTranslation,
        };
    },
});
