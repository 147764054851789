import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4025a397"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-section" }
const _hoisted_2 = {
  class: "form-section bordered",
  style: {"align-items":"center"}
}
const _hoisted_3 = { class: "combined-container-image" }
const _hoisted_4 = { class: "centered" }
const _hoisted_5 = {
  class: "form-section bordered",
  style: {"align-items":"center"}
}
const _hoisted_6 = {
  class: "form-section bordered",
  style: {"align-items":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item_picker = _resolveComponent("item-picker")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    title: _ctx.pageTitle,
    heading: _ctx.pageTitle,
    full: "",
    padded: ""
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_smart_trak_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_button, {
            disabled: !_ctx.saveable,
            variant: "primary",
            onClick: _ctx.onSubmit
          }, {
            default: _withCtx(() => [
              (_ctx.state.submittingInventory)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    small: ""
                  }))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.getTitleCaseTranslation('core.button.submit')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            cols: "5",
            "cols-offset": "3"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.item')), 1),
                _createVNode(_component_item_picker, {
                  key: _ctx.state.componentKey,
                  "item-type": _ctx.ItemType.COMBINED,
                  "search-only": "",
                  "include-only-combined-containers": "",
                  placeholder: _ctx.placeholder,
                  onOnSelect: _ctx.onCombinedContainerSelect,
                  onOnClear: _ctx.resetForm
                }, null, 8, ["item-type", "placeholder", "onOnSelect", "onOnClear"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_b_row, null, {
                  default: _withCtx(() => [
                    _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.unitLoad')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_row, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_thumbnail, {
                        "image-url-thumb": _ctx.state.combinedContainer?.imageUrlThumb,
                        "image-url-full": _ctx.state.combinedContainer?.imageUrlFull,
                        size: "lg"
                      }, null, 8, ["image-url-thumb", "image-url-full"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_row, null, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.combinedContainerDisplayTitle), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_b_row, null, {
                  default: _withCtx(() => [
                    _createElementVNode("label", null, _toDisplayString(_ctx.quantityPlaceholderLabel), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_input, {
                      modelValue: _ctx.state.combinedContainerQtyBuilt,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.combinedContainerQtyBuilt) = $event)),
                      placeholder: _ctx.quantityPlaceholderLabel,
                      "focus-on-create": _ctx.state.combinedContainer != null
                    }, null, 8, ["modelValue", "placeholder", "focus-on-create"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_b_row, null, {
                  default: _withCtx(() => [
                    _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.unitLoadPieces')), 1)
                  ]),
                  _: 1
                }),
                (_ctx.state.combinedContainer?.isUnitLoad)
                  ? (_openBlock(), _createBlock(_component_b_row, { key: 0 }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.combinedContainer?.unitLoadParts, (unitLoadPart) => {
                          return (_openBlock(), _createBlock(_component_b_row, {
                            key: unitLoadPart.partId,
                            class: "combined-container-part-block"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, _toDisplayString(`${unitLoadPart.part.name} (${unitLoadPart.quantity}X)`), 1),
                              _createElementVNode("div", null, _toDisplayString(`${_ctx.getTitleCaseTranslation('core.domain.onHandInventory')}: ${_ctx.state.combinedContainerFinishedInventoryQty[unitLoadPart.partId]}`), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : (_ctx.state.combinedContainer)
                    ? (_openBlock(), _createBlock(_component_b_row, {
                        key: 1,
                        class: "no-definition-error-msg"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTranslation('core.validation.combinedItemDoesNotHaveDefinitionCantUpdate')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "heading"]))
}